<template>
  <TLoading v-if="$fetchState.pending" />
  <t-page v-else>
    <template #header>
      <h3>
        {{
          creating
            ? $t('pages.registration_code.actions.create')
            : $t('pages.registration_code.actions.edit')
        }}
      </h3>
    </template>

    <t-form-card>
      <validation-observer ref="validator" v-slot="{ invalid, handleSubmit }" tag="div">
        <t-form @submit.native.prevent="handleSubmit(save)">
          <t-grid>
            <t-grid-row>
              <t-grid-cell>
                <t-validation-wrapper
                  vid="label"
                  :name="$t('fields.internal_label')"
                  rules="required|min:3|max:255"
                >
                  <RegistrationCodeLabelHelp slot="help" />
                  <t-input
                    v-model="form.label"
                    native-type="text"
                    :placeholder="$t('fields.organization_name_placeholder')"
                  />
                </t-validation-wrapper>
              </t-grid-cell>
            </t-grid-row>

            <t-grid-row>
              <t-grid-cell>
                <t-validation-wrapper
                  vid="occupation_id"
                  :name="$t('fields.occupation')"
                  rules="string"
                >
                  <RegistrationCodeOccupationHelp slot="help" />
                  <OccupationSelect v-model="form.occupation_id" :multiple="false" />
                </t-validation-wrapper>
              </t-grid-cell>
            </t-grid-row>

            <t-grid-row>
              <div
                style="
                  flex-direction: row-reverse;
                  display: flex;
                  width: 100%;
                  justify-content: space-between;
                  align-items: center;
                "
              >
                <t-button-loading :disabled="invalid" :loading="loading" native-type="submit">
                  {{ $t('global.actions.save') }}
                </t-button-loading>

                <a v-if="!creating" href="." @click.prevent="deleteConfirmationVisible = true">{{
                  $t('global.actions.delete')
                }}</a>
              </div>
            </t-grid-row>
          </t-grid>
        </t-form>
      </validation-observer>
    </t-form-card>

    <portal to="modals">
      <t-confirm
        v-if="deleteConfirmationVisible"
        :button-label="$t('global.actions.confirm_deletion')"
        @confirmed="destroy"
        @cancelled="deleteConfirmationVisible = false"
      >
        <template #header>
          {{ $t('global.confirmation.delete') }}
        </template>
        <p>{{ $t('global.confirmation.final_action') }}</p>
      </t-confirm>
    </portal>
  </t-page>
</template>

<script>
import Vue from 'vue';
import RegistrationCode from '~/models/RegistrationCode';
import TLoading from '~/components/TLoading';
import TFormCard from '~/components/TFormCard';
import TValidationWrapper from '~/components/TValidationWrapper';
import OccupationSelect from '~/components/ResourceSelect/OccupationSelect';
import RegistrationCodeLabelHelp from '~/components/Help/RegistrationCodeLabelHelp';
import RegistrationCodeOccupationHelp from '~/components/Help/RegistrationCodeOccupationHelp';
import UserTypeEnum from '~/enums/UserTypeEnum';

export default Vue.extend({

  components: {
    TLoading,
    TFormCard,
    TValidationWrapper,
    OccupationSelect,
    RegistrationCodeLabelHelp,
    RegistrationCodeOccupationHelp,
  },

  props: {
    organizationId: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      registrationCode: null,
      form: null,
      loading: false,

      deleteConfirmationVisible: false,
    };
  },

  async fetch() {
    if (this.$route.params?.registration_code_id) {
      this.registrationCode = await RegistrationCode.$find(this.$route.params.registration_code_id);
    }

    this.form = this.getForm(this.registrationCode);
  },

  computed: {
    creating() {
      return !(this.registrationCode && this.registrationCode.id);
    },
  },

  methods: {
    getForm(registrationCode = null) {
      return {
        label: registrationCode?.label,
        occupation_id: registrationCode?.occupation_id,
        organization_id: this.organizationId,
      };
    },

    getPayload() {
      return {
        ...this.form,
      };
    },

    rerouteToIndex() {
      const destinationRoute = [UserTypeEnum.ADMIN, UserTypeEnum.TENANT_MEMBER].includes(
        this.$auth.me.user.type,
      )
        ? {
            name: this.$RouteEnum.ORGANIZATION.REGISTRATION_CODES,
            params: { id: this.organizationId },
          }
        : { name: this.$RouteEnum.REGISTRATION_CODES.INDEX };
      return this.$router.push(destinationRoute);
    },

    async save() {
      if (this.creating) {
        return await this.create(this.getPayload());
      }

      return await this.update(this.getPayload());
    },

    async create(payload) {
      this.loading = true;
      try {
        await new RegistrationCode({
          ...payload,
        }).save();

        await this.$notify.success(this.$t('notifications.registration_code.created'));
        await this.rerouteToIndex();
      } catch (e) {
        this.$axios.handleError(e, this.$refs.validator);
      } finally {
        this.loading = false;
      }
    },

    async update(payload) {
      this.loading = true;
      try {
        await new RegistrationCode({
          id: this.registrationCode.id,
          ...payload,
        }).patch();

        await this.$notify.success(this.$t('notifications.registration_code.updated'));
      } catch (e) {
        this.$axios.handleError(e, this.$refs.validator);
      } finally {
        this.loading = false;
      }
    },

    async destroy() {
      try {
        await new RegistrationCode({
          id: this.registrationCode.id,
        }).delete();

        await this.$notify.success(this.$t('notifications.registration_code.deleted'));
        await this.rerouteToIndex();

        this.deleteConfirmationVisible = false;
      } catch (e) {
        this.$axios.handleError(e);
      }
    },
  },
});
</script>

<script>
import THelp from '~/components/THelp';

export default {
  extends: THelp,

  props: {
    label: {
      type: String,
      required: false,
      default:
        'Geben Sie dem Empfehlungslink eine eindeutige Bezeichnung, wie z.B. "Bewerber:innen Chemikant:innen"',
    },
  },
};
</script>

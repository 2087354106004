<script>
import THelp from '~/components/THelp';

export default {
  extends: THelp,

  props: {
    label: {
      type: String,
      required: false,
      default:
        'Wenn Bewerber:innen auf einen genauen Ausbildungsberuf getestet wurden, können Sie diesen hier festlegen.',
    },
  },
};
</script>
